import { reactive, ref } from 'vue'
import HealthRecipesProcessor from '/~/extensions/health/core/recipes-processor'
import Storage from '/~/utils/localStorage'
import { useUser } from '/~/composables/user'

const { user } = useUser()
const feed = reactive(
  new HealthRecipesProcessor({
    index: 'production_bodygoodfood',
  })
)
const saved = ref<string[]>(
  Storage.get(`${user.value.externalId}/saved_recipes`) || []
)
const queryState = ref<Record<string, string> | null>(null)

function saveRecipe(recipeId: string) {
  const array = [...saved.value, recipeId]

  Storage.set(`${user.value.externalId}/saved_recipes`, JSON.stringify(array))
  saved.value = array
}
function removeRecipe(recipeId: string) {
  const array = saved.value.filter((id) => id !== recipeId)

  Storage.set(`${user.value.externalId}/saved_recipes`, JSON.stringify(array))
  saved.value = array
}

function isBookmarked(id: string) {
  return saved.value.includes(id)
}

function toggleBookmark(id: string) {
  if (isBookmarked(id)) {
    removeRecipe(id)
  } else {
    saveRecipe(id)
  }
}

export function useRecipes() {
  return {
    feed,
    saved,
    queryState,

    isBookmarked,
    toggleBookmark,
  }
}
